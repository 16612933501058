@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "PPSupplyMono-Light";
  src: local(PPSupplyMono-Light), url("./styles/fonts/PPSupplyMono-Light.ttf") format("ttf");
}

@font-face {
  font-family: "PPSupplyMono-Regular";
  src: local(PPSupplyMono-Regular), url("./styles/fonts/PPSupplyMono-Regular.otf") format("otf");
}

@font-face {
  font-family: "Roobert-Regular";
  src: local(Roobert-Regular), url("./styles/fonts/Roobert-Regular.otf") format("otf");
}

@font-face {
  font-family: "Roobert-Medium";
  src: local(Roobert-Medium), url("./styles/fonts/Roobert-Medium.otf") format("otf");
}

@font-face {
  font-family: "Roobert-SemiBold";
  src: local(Roobert-SemiBold), url("./styles/fonts/Roobert-SemiBold.otf") format("otf");
}

@font-face {
  font-family: "Roobert-Bold";
  src: local(Roobert-Bold), url("./styles/fonts/Roobert-Bold.otf") format("otf");
}

body {
  margin: 0;
  font-family: sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


* {
  margin: 0;
  padding: 0px;
  box-sizing: border-box;
}